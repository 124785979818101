import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Section from 'src/components/Section'
import Heading from 'src/components/Heading'
import CategoriesBar from 'src/components/CategoriesBar'
import PostGrid from 'src/components/PostGrid'
import Pagination from 'src/components/Pagination'
import PagePropTypes from 'src/utils/propTypes'
import Seo from 'src/components/Seo'
import { getThemeVar } from 'src/styles/mixins'

export const BlogTemplate = ({
  title,
  posts,
  categories,
  acf,
  numPages,
  uri,
  readMoreLabel
}) => (
  <Section
    background={getThemeVar('color.lightblue')}
    hasPadding
    paddingValues={{ xs: '32px 0 80px 0', lg: '72px 0 100px 0' }}
    fullHeight
  >
    <Heading>{title}</Heading>
    <CategoriesBar
      isBlogTemplate
      categories={categories}
      defaultItem={{ uri, name: acf.defaultCategoryName }}
    />
    <PostGrid posts={posts} readMoreLabel={readMoreLabel} />
    <Pagination numPages={numPages} uri={uri} />
  </Section>
)

BlogTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        excerpt: PropTypes.string,
        featuredImage: PropTypes.object, // eslint-disable-line
      }),
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
        uri: PropTypes.string,
      }),
    })
  ).isRequired,
  acf: PropTypes.shape({
    readMoreLabel: PropTypes.string,
    defaultCategoryName: PropTypes.string,
  }).isRequired,
  numPages: PropTypes.number.isRequired,
  uri: PropTypes.string.isRequired,
  readMoreLabel: PropTypes.string,
}

BlogTemplate.defaultProps = {
  readMoreLabel: "Read more",
}

const Page = ({ data, pageContext }) => {

  let readMore;
  const options = data.allWp.edges[0].node.siteGeneralSettings.acfOptions;
  switch (data.wpPage.language.locale) {
    case 'en_US':
      readMore = options.readMoreEn
      break
    case 'ja':
      readMore = options.readMoreJa
      break
    default:
      readMore = options.readMoreDe
      break
  }

  return (
    <Layout page={data.wpPage} pageLocale={data.wpPage.language.locale}>
      <Seo
        title={data.wpPage.title}
        locale={data.wpPage.language.locale}
        description={data.wpPage.seo.metaDesc}
      />
      <BlogTemplate
        title={data.wpPage.title}
        posts={data.allWpPost.edges}
        categories={data.allWpCategory.edges}
        acf={data.wpPage.acfBlog}
        numPages={pageContext.numPages}
        uri={data.wpPage.uri}
        readMoreLabel={readMore}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogById($id: String!, $locale: String!, $limit: Int!, $skip: Int!) {
    ...readMoreLabel
    wpPage(id: { eq: $id }) {
      title
      uri
      ...pageTranslationData
      seo {
        metaDesc
      }
      acfBlog {
        defaultCategoryName
      }
    }

    allWpPost(
      filter: { language: { locale: { eq: $locale } } }
      sort: { order: DESC, fields: date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          content
          excerpt
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 550, maxHeight: 385) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpCategory(
      filter: { count: { gte: 1 }, language: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          name
          uri
        }
      }
    }
  }
`

Page.propTypes = PagePropTypes

export default Page
